@if $nav-type == mega {
  @if $warn == true {
    @warn ("Don't forget to set JS variable megaMenu to true!");
  }

  .menuMain {
    width: 100%;
    background-color: $nav-background-colour;
    padding: 0;
    border-radius: $nav-normal-border-radius;

    // Hide this cloned in item
    .menuMainAlt {
      display: none;
    }
  }

  ul.topLevel {
    position:  relative;
    display: flex;
    margin: 0 auto;
  }

  @media (min-width: $nav-breakpoint + 1px) {
    .menuMain {
      max-width: $nav-normal-max-width;
      @include parentless-container-wrapper($nav-normal-max-width);
      margin-top: $nav-normal-margin-top;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $nav-normal-margin-bottom;

      // If we have a transparent bar, then we want the item TEXT to align vertically
      // with the header content above it. This is assuming that the top level item's
      // lateral padding is 20px (* 2 = 40)
      @if $nav-background-colour == transparent {
        @include parentless-container-wrapper(
          #{$nav-normal-contents-max-width} + 40px
        );
      }
    }

    .menuMainAlt {
      display: none;
    }

    ul.topLevel {
      max-width: $nav-normal-contents-max-width;

      @if $nav-normal-border-radius != 0 and $nav-normal-align-items == left {
        > li:first-child > a {
          border-top-left-radius: $nav-normal-border-radius;
        }
      }

      @if $nav-normal-align-items == center {
        justify-content: center;
      }

      @if $nav-normal-align-items == right {
        justify-content: flex-end;
      }
    }

    .menuMain .topLevel li.level1 {

      > a {
        color: $nav-top-level-item-colour;
        font-family: $nav-top-level-item-font-family;
        font-size: $nav-top-level-item-font-size;
        font-weight: $nav-top-level-item-font-weight;
        padding: $nav-top-level-item-padding;
        background-color: $nav-top-level-item-background-colour;

        &.active {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            opacity: 1 !important;
            top: calc(100% + #{$nav-mega-margin-top}) !important;
            right: auto;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, calc(-100% + 5px));
            width: $spacer * 2;
            height: $spacer;
            background-color: $nav-mega-background-colour;
            clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
            z-index: 2;
          }
        }
      }

      &:hover > a {
        color: $nav-top-level-item-hover-colour;
        background-color: $nav-top-level-item-hover-background-colour;
      }
    }


    ul.subMenu.level2 {
      position: absolute;
      z-index: 2;
      width: calc(100% - #{$spacer * 2});
      max-width: $nav-mega-max-width;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: $nav-mega-margin-top !important;
      background-color: $nav-mega-background-colour;
      border-radius: $nav-mega-border-radius;
      padding: #{$gap-width / 4};
      box-shadow: $nav-mega-box-shadow;
      
      
      li.level2 {
        display: inline-flex;
        flex-direction: column;
        margin: #{$gap-width / 4};
        width: calc((100% / #{$nav-mega-submenu-per-row}) - #{$gap-width / 2});
        flex-grow: 0;
        border-radius: $nav-mega-submenu-border-radius;
        overflow: hidden;
        background-color: $nav-mega-submenu-background-colour;
        box-shadow: $nav-mega-submenu-submenu-box-shadow;
        > a {
          background-color: $nav-mega-submenu-heading-background-colour;
          color: $nav-mega-submenu-heading-colour;
          &:hover { 
            background-color: $nav-mega-submenu-heading-hover-background-colour;
            color: $nav-mega-submenu-heading-hover-colour;
          }
        }
      }

      li.level3 > a {
        color: $nav-mega-submenu-item-colour;
        &:hover {
          background-color: $nav-mega-submenu-item-hover-background-colour;
          color: $nav-mega-submenu-item-hover-colour;
        }
      }
      
    }
    
    ul.subMenu.level3 {
      position: relative;
      left: 0 !important;
      display: block !important;
      height: auto !important;
      background-color: $nav-mega-submenu-background-colour;
    }
    
    ul.subMenu:not(.level2) {
      position: relative;
      width: 100%;
    }
  }
  
  // Hide any nav level 4
  @media (max-width: #{$nav-breakpoint  + 1px}) {
    .level4 {
      display: none;
    }
  }

  
  //
  // Responsive
  //

  @media (max-width: $nav-breakpoint) {
    .pageHeader {
      position: relative;
    }

    .menuMain {
      position: absolute;
      max-height: 0;
      transition: all $transition-duration $transition-curve;
      z-index: 2;
      overflow: hidden;
      background-color: $nav-normal-mobile-background-colour;

      &.active {
        // max-height is being calculated with JS to insure it stretches to the bottom of the viewport
        overflow-y: scroll;
      }
    }

    .menuMain ul.topLevel {
      flex-direction: column;
      margin: 0;
    }

    // Set primary link colour
    .menuMain .topLevel > li > a {
      color: $nav-normal-mobile-top-level-item-colour;
      &:hover {
        color: $nav-normal-mobile-top-level-item-colour;
      }
    }

    .menuMain .subMenu {
      max-height: 0;
      display: block;
      transition: all $transition-duration $transition-curve;
      overflow: hidden;
      background-color: $nav-normal-mobile-submenu-background-colour;

      a {
        color: $nav-normal-mobile-submenu-item-colour;

        &:hover {
          color: $nav-normal-mobile-submenu-item-colour;
        }
      }

      &.active {
        max-height: unset;
      }
    }
  }

  @media (max-width: $cta-btn-breakpoint) {
    @if $cta-btn-background-colour == $nav-normal-mobile-background-colour {
      .menuMain .cta-button:not([class*="donate"]) {
        background-color: darken($cta-btn-background-colour, 10%);
      }
    }

    @if $donate-colour == $nav-normal-mobile-background-colour {
      .menuMain .cta-button.donate {
        background-color: darken($donate-colour, 10%);
      }
    }
  }
}
